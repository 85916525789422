.application-wrapper {
    @include fullpage-absolute;
    background: $color-black;
    user-select: none;

}

.application-bkg{
    position: absolute;
    top: -20%;
    left: 0;
    height: 140%;
    width: 100%;
    background-position: center center;
    background-size: cover;
}


.application-container {
    position: relative;
    height: 100%;
    width: 100%;
    background: rgba($color-primary, 0.5);

}

.bt, .br, .bb, .bl{
    position: absolute;
    background: $color-white-lighter;
    z-index: $index-overlay + 1;
}
.bt{

    top: $application-border-padding;
    left: $application-border-padding;
    height: 1px;
    width: calc(100% - 40px);

}

.br{
    top: $application-border-padding;
    right: $application-border-padding;
    width: 1px;
    height: calc(100% - 40px);
}

.bb{
    bottom: $application-border-padding;
    left: $application-border-padding;
    height: 1px;
    width: calc(100% - 40px);
}

.bl{
    top: $application-border-padding;
    left: $application-border-padding;
    width: 1px;
    height: calc(100% - 40px);
}
