.title{
	position: relative;
	width: auto;
	color: $color-black;
	font-size: 2rem;
	margin-bottom: 0.75*$application-viewport-padding;
	display: inline-block;
}

.title-line{
	width: auto;
	margin: 0;
	padding: 0;
	letter-spacing: 0.3rem;
}

.mid-col-content{
	display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
}

.mid-col{
	// position: relative;
	display: flex;
	flex-direction: column;
	flex: 0 1 auto;
	align-self: auto;
	min-width: 50%;
	max-width: 50%;
	margin-top: 1.5*$application-viewport-padding;
}

.mid-col-left{
	order: 1;
	padding-right: 6%;
}
.mid-col-right{
	order: 2;
	padding-left: 6%;
}

.block-text{
	position: relative;
}

.block-text-title{
	position: relative;
	color: $color-primary;
	font-size: 1rem;
}

.block-text-paragraph{
	position: relative;
	overflow: hidden;
	margin-top: 0.75*$application-border-padding;
	color: $color-text-grey;
}

.btn{
	position: relative;
	display: inline-block;
	background: $color-white;
	color: $color-text-grey-darker;
    text-transform: uppercase;
    border: 1px solid $color-text-grey;
    padding: 1.7rem 2rem 1.5rem 2rem;
    &:hover{
		color: $color-primary;
		border: 1px solid $color-primary;
		.back-arrow::before, .back-arrow::after{
			background-color: $color-primary;
		}
    }
}





@media screen and (max-width: 1435px){

	body{
		font-size: 80%;
	}

	.mid-col{
		margin-top: $application-border-padding;
	}

	.project-view-left{
		padding: 100px;
	}

	.about-right{
		padding: 100px;
	}

	.project-view-index{
		left: auto;
		margin-left: 0;
	    bottom: #{$application-viewport-padding};
		right: 6vw;
	    font-size: 7rem;
	    span{
	        line-height: 45px;
	    }
	}

	.project-view-right .project-view-right-inner{
	    padding: 160px 3vw;
	    position: relative;
	    width: 100%;
	    margin: 0 auto;
	    height: 100%;
	}

	.about-title .about-title-word{
		font-size: 1.2rem;
	}

	.about .about-right-inner .btn {
	    margin-top: 2rem;
	}

	.project-title .project-title-word{
		font-size: 1.2rem;
	}

	.project-view-title .project-view-title-word{
		font-size: 1.2rem;
	}

	.home-title{
		font-size: 1.2rem;
	}

}

.home{

}

.about{

	footer .footer-container-right .footer-mail a, footer .footer-container-right .footer-social a{
		color: $color-text-grey;
		transition: color 0.3s;
		&:hover{
			color: $color-primary;
		}
	}

}

.project{

	footer .footer-container-right .footer-mail a, footer .footer-container-right .footer-social a{
		color: $color-white;
		transition: color 0.3s;
		&:hover{
			color: $color-primary;
		}
	}

}

.project-view{

	header .header-about-button, header .header-works-button{
		color: $color-text-grey;
		transition: color 0.3s;
		&:hover{
			color: $color-primary;
		}
	}

	header .header-developer{
		color: $color-text-grey;
		transition: color 0.3s;
		.header-developer-back, .header-developer-back.active{
            background: $color-text-grey;
            &:before, &:after{
                background: $color-text-grey;
            }
        }
		&:hover{
			color: $color-primary;
			.header-developer-back, .header-developer-back.active{
	            background: $color-primary;
	            &:before, &:after{
	                background: $color-primary;
	            }
	        }
		}
	}

	footer .footer-container-left .footer-x-inner, footer .footer-container-left .footer-y-inner {
		color: $color-text-grey;
		transition: color 0.3s;
	}
}
