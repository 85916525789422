.home-wrapper {
    @include fullpage-absolute;
    z-index: $index-application;
    background: $color-black;

}

.home-bkg{
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    filter: brightness(0.9);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
}


.home-container {
    position: relative;
    height: 100%;
    width: 100%;
    padding: $application-viewport-padding;
    background: rgba($color-primary, 0.5);
}

.home-square{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
}

.home-title{
    position: relative;
    font-size: 1.35rem;
    font-weight: 100;
    margin: 0;
    padding: 0;
    display: block;
    color: $color-white;
    height: 35px;
    margin-top: 0.45em;
    margin-bottom: 0.65em;
}

.home-subtitle{
    position: relative;
    font-weight: 100;
    margin: 0;
    padding: 0;
    display: block;
    color: $color-white;
    span{
        display: block;
        height: 35px;
    }
}

.home-title-word{
    width: 100%;
    font-weight: 100;
    margin: 0;
    padding: 0;
    position: relative;
    display: block;
    color: $color-white;
    line-height: 1;
    margin-bottom: 0.65em;
    overflow: hidden;
    .home-letter{
        width: 35px;
        height: 45px;
        margin: 0;
        padding: 0;
        text-align: center;
        line-height: 1;
        display: inline-block;
    }
}

.home-subtitle-word{
    color: rgba($color-white, 0.9);
    width: 100%;
    font-weight: 100;
    margin: 0;
    padding: 0;
    position: relative;
    display: block;
    color: $color-white;
    line-height: 1;
    margin-bottom: 0.65em;
    overflow: hidden;
    .home-letter{
        width: 35px;
        height: 45px;
        margin: 0;
        padding: 0;
        text-align: center;
        line-height: 1;
        display: inline-block;
    }
    &:nth-child(1) .bar{
        position: absolute;
        right: 12px;
        top: 5px;
        width: 133px;
        height: 2px;
        background: rgba($color-primary, 0.9);
    }
    &:nth-child(2) .bar{
        position: absolute;
        right: 12px;
        top: 5px;
        width: 0px;
        height: 2px;
        background: rgba($color-primary, 0.9);
    }
}


.home-mask-line{
    z-index: 2;
    position: absolute;
    left: 0;
    width: 480px;
    height: 22px;
    overflow: hidden;
    .home-mask-line-inner{
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba($color-white, 1);
        transform: translateX(-110%);
    }
}

.home-mask-line-1{
    top: 8px;
}
.home-mask-line-2{
    top: 53px;
}
.home-mask-line-3{
    top: 100px;
}

.home-loading{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 150px);
    width: 200px;
    height: 40px;
}

.home-loading-counter{
    position: relative;
    background: $color-white;
    margin: 0;
    font-weight: 100;
    height: 40px;
    line-height: 40px;
    color: $color-primary;
    border: 1px solid $color-white;
    float: left;
    text-align: center;
    width: 80px;
    opacity: 0;
}


.home-loading-title{
    position: relative;
    color: $color-white;
    margin: 0;
    font-weight: 100;
    width: 120px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    float: left;
    text-transform: uppercase;
    border: 1px solid $color-white;
    opacity: 0;
}

.home-loading-bar{
    position: absolute;
    width: 200px;
    height: 40px;
    left: 0px;
    top: 0px;
    overflow: hidden;
    opacity: 0;
}

.home-loading-bar-inner{
    position: absolute;
    width: 100%;
    height: 40px;
    left: 0px;
    top: 0px;
    border: 1px solid $color-white;
    background: $color-white;
    transform: translateX(-200px);
}

.home-work {
    position: absolute;
    bottom: #{$application-viewport-padding + 50 + 14};
    left: 50%;
    transform: translateX(-50%);
}

.home-work-border{
    position: absolute;
    width: 1px;
    height: 50px;
    left: 50%;
    margin-top: 14px;
    background: rgba($color-white, 0.3);
    overflow: hidden;
}

.home-work-border-highlight{
    position: absolute;
    width: 100%;
    height: 100%;
    background: $color-white;
    animation: homeWork 2s infinite ease-in-out;
}

.home-work-link{
    text-transform: uppercase;
}
