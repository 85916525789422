.footer-wrapper {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.footer-container-left{
    position: absolute;
    bottom: 0;
    left: 0;
    padding: $application-viewport-padding;
}
.footer-container-right{
    position: absolute;
    bottom: 0;
    right: 0;
    padding: $application-viewport-padding;
}

.footer-x{
    position: relative;
    width: 80px;
    display: inline-block;
    color: $color-primary;
    span{
        color: $color-white;
        transition: color 0.3s;
    }
    z-index: $index-footer;

}

.footer-y{
    position: relative;
    width: 80px;
    margin-left: 5px;
    display: inline-block;
    color: $color-primary;
    span{
        color: $color-white;
        transition: color 0.3s;
    }
    z-index: $index-footer;

}

.footer-mail{
    position: relative;
    float: right;
    display: inline-block;
    margin-right: #{$application-border-padding*2 + 170};
    z-index: $index-footer;

}
.footer-mail-border{
    position: absolute;
    margin-top: 7px;
    margin-left: $application-border-padding;
    height: 1px;
    width: 150px;
    background: $color-white-lighter;
    z-index: $index-footer;

}

.footer-social{
    float: right;
    right: $application-viewport-padding;
    bottom: 22px;
    display: inline-block;
    position: absolute;
    z-index: $index-footer;

}

.footer-social-element{
    list-style: none;
    line-height: $line-height;
    z-index: $index-footer;
}

.footer-social-element{

}
