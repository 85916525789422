// Layout

@mixin vh-align-translate() {

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}

@mixin v-align-translate() {

	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

@mixin h-align-translate() {

	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

@mixin fullpage-absolute() {

	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}
