.about-wrapper {
    @include fullpage-absolute;
    z-index: $index-application;
}

.about-container {
    position: relative;
    height: 100%;
    width: 100%;
    padding: $application-viewport-padding;
}

.about-left-mask{
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: $index-application+1;
    overflow: hidden;
}

.about-left{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    perspective: 1000px;
}

.about-left-background-back{
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background: no-repeat;
    filter: brightness(1);
    background-position: center left;
    background-size: cover;
    transform-style: preserve-3d;
}

.about-left-background-front{
    position: absolute;
    top: -20%;
    left: -30%;
    width: 140%;
    height: 140%;
    background: url('/media/img/developer-front.png') no-repeat;
    background-position: center center;
    background-size: cover;
    transform-style: preserve-3d;
}

.about-left-inner{
    position: relative;
    padding: 6%;
    width: 100%;
    height: 100%;
    background: rgba($color-primary, 0.5);
}

.about-left-inner-workers{
    position: absolute;
    bottom: $application-viewport-padding;
    right: $application-viewport-padding;
    z-index: $index-footer + 1;
}

.about-left-inner-worker{
    margin-top: 1.5rem;
}

.worker-function{
    color: $color-primary;
    font-size: 1rem;
    margin-bottom: 0.7rem;
}

.worker-name{
    font-size: 1rem;
    margin: 0;
    // text-transform: uppercase;
}

.about-right{
    position: absolute;
    // padding: 6vw;
    padding: #{3 * $application-viewport-padding};
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    background: $color-white;
}

.about-right-mask{
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: $index-application+1;
    overflow: hidden;
}

.about-right-content{
    overflow: hidden;
}

.about-right-inner{
    .title::before{
        content: "";
        position: absolute;
        right: 20px;
        height: 8px;
        width: 220px;
        background: $color-primary;
        margin-top: 22px;
        z-index: 3;
    }
    .title .title-line{
        z-index: 4;
        &:nth-child(1){
            letter-spacing: 0.2em;
            //color: $color-primary;
        }
        &:nth-child(2){
            letter-spacing: 0.2em;
        }
        &:nth-child(3){
            letter-spacing: 0.32em;
        }
    }
    .btn{
        margin-top: 3rem;
    }
}

.about-square{
    width: auto;
    position: relative;
}
.about-title{
    position: relative;
    width: auto;
    top: 0;
    padding: 0;
    margin: 0;
    line-height: 1;
    .about-title-word{
        width: 100%;
        font-size: 1.5rem;
        font-weight: 100;
        margin: 0;
        padding: 0;
        position: relative;
        display: inline-block;
        color: $color-black-lighter;
        line-height: 1;
        margin-bottom: -7px;
        &:nth-child(1){
            &::before{
                content: "";
                position: absolute;
                left: 175px;
                top: 6px;
                width: 223px;
                height: 3px;
                background: rgba($color-primary, 1);
            }
        }
        &:nth-child(2){
            &::before{
                content: "";
                position: absolute;
                left: 280px;
                top: 6px;
                width: 118px;
                height: 3px;
                background: rgba($color-primary, 1);
            }
        }
        &:nth-child(3){
        }
        .about-letter{
            width: 35px;
            height: 45px;
            margin: 0;
            padding: 0;
            text-align: left;
            line-height: 1;
            float: left;
        }
    }
}

.about-mask-line{
    z-index: 2;
    position: absolute;
    top: 0px;
    left: 0;
    width: 400px;
    height: 20px;
    overflow: hidden;
    .about-mask-line-inner{
        position: absolute;
        width: 100%;
        height: 100%;
        background: $color-primary;
        transform: translateX(-100%);
    }
}

.about-mask-line-1{
    top: -3px;
}
.about-mask-line-2{
    top: 40px;
}
.about-mask-line-3{
    top: 84px;
}

.about-right-content{
    display: block;
    position: relative;
    height: auto;
    .block-text {
        position: relative;
        transform: translateY(0px);
        padding-bottom: 1.2em;
    }
}
