@font-face {
    font-family: 'Pier';
    src: url('/media/fonts/pier-bold.otf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Pier';
    src: url('/media/fonts/pier-regular.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Argo';
    src: url('/media/fonts/argo.ttf');
    font-weight: normal;
    font-style: normal;
}
