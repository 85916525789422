.menu-wrapper {
    @include fullpage-absolute;
    padding: $application-border-padding;
    z-index: $index-application;
    background: rgba($color-black, 0.95);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: background 0.5s;
    z-index: $index-overlay;

}

.menu-wrapper-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color-primary, 0.12);
}

.menu-container {
    position: relative;
    height: 100%;
    width: 100%;
    border: 1px solid rgba($color-white, 0.3);
    padding: #{3*$application-viewport-padding};
}

.menu-project-wrapper{
    height: 100%;
}

.menu-project-element{
    display: inline-block;
    width: 33%;
    height: 50%;
    position: relative;
    &:nth-child(2){
        width: 34%;
    }
}
//
// .menu-project-index{
//     position: absolute;
//     left: 50%;
//     top: 50%;
//     transform: translate(-50%, -39%);
//     font-size: 24rem;
//     font-weight: bold;
//     text-align: center;
//     letter-spacing: 2rem;
//     color: rgba($color-white, 0.1);
//     span{
//         opacity: .4;
//     }
// }
//
// .menu-project-square{
//     position: absolute;
//     left: 50%;
//     top: 50%;
//     transform: translate(-50%, -50%);
//     border: 2px solid rgba($color-white, 0.5);
//     width: 200px;
//     height: 200px;
// }
//
// .menu-project-title{
//     position: absolute;
//     bottom: -12px;
//     padding: 0;
//     margin: 0;
//     padding-left: 12px;
//     padding-right: 10px;
//     .menu-project-title-word{
//         width: 100%;
//         font-size: 1.2rem;
//         font-weight: 100;
//         margin: 0;
//         padding: 0;
//         position: relative;
//         display: inline-block;
//         line-height: 1;
//         margin-bottom: -10px;
//         .bar{
//             position: absolute;
//             right: 12px;
//             bottom: 33px;
//             width: 0px;
//             height: 3px;
//             background: rgba($color-white, 0.7);
//         }
//         .menu-project-letter{
//             width: 37px;
//             height: 40px;
//             text-align: center;
//             margin: 0;
//             padding: 0;
//             line-height: 1;
//             float: left;
//         }
//     }
// }
//





.menu-project-square{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgba($color-white, 1);
    width: 250px;
    height: 250px;
    overflow: hidden;
    z-index: 1;
    &:hover{
        color: inherit;
    }
}

.menu-project-square-mask{
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: $color-white;
    z-index: $index-overlay;
    transform: translateX(-100%);
}

.menu-project-square-blur{
    // display: none;
    position: absolute;
    top: calc(-50vh + 125px);
    left: calc(-50vw + 125px);
    width: 100vw;
    height: 100vh;
    background: center center;
    background-size: cover;
    filter: blur(10px);
    &::after{
        position: absolute;
        background: rgba($color-primary, 0.12);
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}


.menu-project-title{
    position: relative;
    padding: 0;
    margin: 0;
    margin-top: 115px;
    transform: translateY(-50%);


    padding-left: $application-border-padding;
    padding-right: $application-border-padding;
    .menu-project-title-word{
        width: 100%;
        font-size: 1.35rem;
        font-weight: 100;
        margin: 0;
        padding: 0;
        position: relative;
        display: inline-block;
        line-height: 1;
        margin-bottom: -8px;
        overflow: hidden;
        .bar{
            position: absolute;
            right: 12px;
            bottom: 38px;
            width: 0px;
            height: 3px;
            background: rgba($color-primary, 0.9);
            transform: translateY(13px);
        }
        .menu-project-letter{
            position: relative;
            transform: translateY(13px);
            width: 34px;
            height: 45px;
            text-align: center;
            margin: 0;
            padding: 0;
            line-height: 1;
            float: left;
        }
    }
}


.menu-project-infos{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: auto;
    border-top: 1px solid $color-white;

}

.menu-project-infos-year{
    position: relative;;
    margin: 0;
    padding-top: 1em;
    padding-bottom: 0.7em;

    font-weight: 100;
    display: inline-block;
    border-right: 1px solid $color-white;
    padding-left: #{$application-border-padding+3px};

    width: 80px;


}

.menu-project-infos-type{
    position: relative;;
    margin: 0;
    padding-top: 1em;
    padding-bottom: 0.7em;

    font-weight: 100;
    display: inline-block;
    text-align: right;
    padding-right: #{$application-border-padding+3px};

    width: 164px;
}
