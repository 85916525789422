.loading-wrapper {
    @include fullpage-absolute;
    z-index: #{$index-overlay + 100};

}

.loading-container {
    position: relative;
    height: 100%;
    width: 100%;
    padding: $application-viewport-padding;
    background: $color-black;
}

.loading-title{

}
