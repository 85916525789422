.mobile-wrapper{
    @include fullpage-absolute;
    color: $color-white;
    user-select: none;
    background-size: cover;
    background-position: left center;
    font-size: 100%;
    z-index: $index-overlay;
}

.mobile-container{
    position: relative;
    height: 100%;
    width: 100%;
    background: rgba($color-primary, 0.5);
    overflow: scroll;
}

.mobile-inner{
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    min-width: 250px;
}

.mobile-title{
    position: relative;
    font-size: 1.35rem;
    font-weight: 100;
    margin: 0;
    padding: 0;
    display: block;
    color: $color-white;
    letter-spacing: 7.6px;
}

.mobile-subtitle{
    position: relative;
    font-weight: 100;
    font-size: 1.2rem;
    margin: 0;
    margin-top: 1em;
    margin-bottom: 3em;
    padding: 0;
    display: block;
    color: $color-white-lighter;
    letter-spacing: 2px;
}

.mobile-paragraph{
    text-align: left;
    font-size: 1rem;
}

.mobile-projects-list{
    text-align: left;
    list-style: none;
    margin-top: 2em;
    padding: 0;
    li{
        padding: 0;
        margin-left: 20px;
        list-style: none;
        &:before{
            content: "";
            position: absolute;
            margin-left: -20px;
            margin-top: #{$line-height/2};
            height: 1px;
            width: 10px;
            background: $color-white;
        }
        a{
            font-size: 1rem;
            line-height: $line-height;
        }
    }
}
