.project-view-wrapper {
    @include fullpage-absolute;
    z-index: $index-application;
    // background: $color-black;
    background: fixed center center;
    background-size: cover;
}

.project-view-container {
    position: relative;
    height: 100%;
    width: 100%;
    padding: $application-viewport-padding;
    // background: rgba($color-primary, 0.3);
}

.project-view-left-mask{
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: $index-application+1;
    overflow: hidden;
}

.project-view-right-mask{
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: $index-application+1;
    overflow: hidden;
}


.project-view-left{
    position: absolute;
    padding: #{3 * $application-viewport-padding};
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: $color-white;
    z-index: $index-application+1;
}


.project-view-left-content{
    overflow: hidden;
    .block-text {
        position: relative;
        transform: translateY(0px);
    }
}

.project-view-left-inner{
    position: relative;

    .title .title-line{
        z-index: 4;
        letter-spacing: 0.2em;
    }
    .btn{
        margin-top: 4rem;
        padding: 1.7rem 2rem 1.5rem 2rem;
    }
    .btn.back-btn{
        padding: 1.7rem 2rem 1.5rem 3rem;
    }
}

.back-arrow{
    position: absolute;
    height: 2px;
    left: 28px;
    top: 48%;
    transition: transform 0.3s, opacity 0.3s;
    &:before{
        content: "";
        position: absolute;
        background: $color-text-grey;
        height: 2px;
        width: 10px;
        right: 0;
        margin-right: 0px;
        top: -3px;
        transform: rotate(-40deg);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        -o-backface-visibility: hidden;
        backface-visibility: hidden;
    }
    &:after{
        content: "";
        position: absolute;
        background: $color-text-grey;
        height: 2px;
        width: 10px;
        right: 0;
        margin-right: 0px;
        top: 3px;
        transform: rotate(40deg);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        -o-backface-visibility: hidden;
        backface-visibility: hidden;
    }
}


.project-view-right{
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    perspective: 1000px;
    z-index: $index-application;
}

.project-view-right-background-back{
    position: absolute;
    top: -20%;
    left: -100%;
    width: 200%;
    height: 140%;
    background-position: center center;
    background-size: cover;
    transform-style: preserve-3d;
    opacity: 0.8;
}

.project-view-right-inner{
    // padding: #{3*$application-viewport-padding};
    padding: 6vw;
    padding-top: #{4.5*$application-viewport-padding};
    padding-bottom: #{4.5*$application-viewport-padding};
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    transition: padding 0.5s ease-in-out;
    overflow: hidden;
}

.project-view-infos-mask{
    position: absolute;
    margin-top: -5px;
    margin-left: 0;
    height: 20px;
    width: 120px;
    overflow: hidden;
    &:nth-child(2){
        top: 27px;
    }
}

.project-view-infos-mask-inner{
    background: $color-white;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);

}

.project-view-right-inner-infos{
    position: absolute;
    margin-top: -5rem;
    margin-left: #{2*$application-viewport-padding};
}

.project-view-right-inner-infos-type{
    color: rgba($color-white, 0.5);
    margin: 0;
    font-weight: 100;
}

.project-view-right-inner-infos-year{
    font-size: 1.5rem;
    margin: 0;
    margin-top: 0.5rem;
}

.project-view-right-scrollr{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    padding-left: #{2*$application-viewport-padding};
    padding-right: #{2*$application-viewport-padding};
    overflow:hidden;
    .iScrollVerticalScrollbar{
        position: absolute;
        height: #{3*$application-viewport-padding};
        background: $color-white;
        width: 1px!important;
        overflow: visible!important;
        top: auto!important;
        margin-right: #{0.5*$application-viewport-padding};
        .iScrollIndicator{
            width: 1rem!important;
            margin-left: -0.95rem;
            height: 2px!important;
            background: $color-primary!important;
            border: none!important;
            &::after{
                content: "";
                position: absolute;
                background: $color-primary;
                width: 10px;
                height: 2px;
                left: 1rem;
            }
        }
    }
}

.project-view-right-scrollr-inner{
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    cursor: ns-resize;
    img{
        width: 100%;
        height: auto;
    }
}



.project-view-index{
    position: absolute;
    left: 50%;
    margin-left: #{1.5*$application-viewport-padding};
    bottom: #{$application-viewport-padding};
    font-size: 20rem;
    font-weight: bold;
    color: $color-primary;
    display: inline-block;
    vertical-align: bottom;
    overflow: hidden;
    span{
        line-height: 140px;
        display: inline-block;
        vertical-align: bottom;
    }
}

.project-view-right-inner-more{
    margin-left: #{2*$application-viewport-padding};
    margin-top: #{0.5*$application-viewport-padding};
    color: $color-white;
    font-weight: bold;
    font-size: 1.17rem;
    text-transform: uppercase;
}

.project-view-square{
    position: relative;
    // border: 2px solid rgba($color-black-lighter, 1);
    width: 210px;
    // height: 200px;
}

.project-view-title{
    position: relative;
    top: 0;
    padding: 0;
    margin: 0;
    line-height: 1;
    .project-view-title-word{
        width: 100%;
        font-size: 1.35rem;
        font-weight: 100;
        margin: 0;
        padding: 0;
        position: relative;
        display: inline-block;
        color: $color-black-lighter;
        line-height: 1;
        margin-bottom: -8px;
        .bar{
            position: absolute;
            left: 20px;
            bottom: 36px;
            width: 170px;
            height: 3px;
            background: rgba($color-primary, 1);
            z-index: 1;
        }
        .project-view-letter{
            position: relative;
            z-index: 2;
            background: $color-white;
            width: 35px;
            height: 45px;
            margin: 0;
            padding: 0;
            text-align: left;
            line-height: 1;
            float: left;
        }
    }
}


.project-view-mask-line{
    z-index: 2;
    position: absolute;
    top: 0px;
    left: 0;
    width: 190px;
    height: 20px;
    overflow: hidden;
    .project-view-mask-line-inner{
        position: absolute;
        width: 100%;
        height: 100%;
        background: $color-primary;
        transform: translateX(-190px);
    }
}

.project-view-mask-line-1{
    top: -3px;
}
.project-view-mask-line-2{
    top: 40px;
}
.project-view-mask-line-3{
    top: 84px;
}
