
.projects-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: $index-application;
}


.project-background{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    &.active{
        z-index: 2;
        opacity: 1;
    }
}

.bkg-inner{
    position: absolute;
    width: 200%;
    height: 140%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    &::after{
        position: absolute;
        content: "";
        background: rgba($color-primary, 0.5);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}


.bkg-left{
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
    .bkg-inner{
        top: -20%;
        left: 0;
    }
}

.bkg-right{
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
    .bkg-inner{
        top: -20%;
        right: 0;
    }
}


.projects-container {
    position: relative;
    height: 100%;
    width: 100%;
}


.project-square{
    opacity: 0;
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgba($color-white, 1);
    // background: $color-primary;
    width: 250px;
    height: 250px;
    overflow: hidden;
    z-index: 2;
    &:hover{
        color: inherit;
    }
}

.project-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: no-repeat center center fixed;
    background-size: cover;
    z-index: 1;
    opacity: 0;
    &.active{
        z-index: 2;
        opacity: 1;
        .project-square{
            z-index: 2;
        }
    }
}

.project-container{
    position: relative;
    width: 100%;
    height: 100%;
    padding: $application-viewport-padding;
}

.project-square-mask{
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: $color-white;
    z-index: $index-overlay;
    transform: translateX(-100%);
}

.project-square-mask-line{
    position: absolute;
    top: 0px;
    left: $application-border-padding;
    width: #{250 - 2*$application-border-padding};
    height: 20px;
    overflow: hidden;
    .project-square-mask-line-inner{
        position: absolute;
        width: 100%;
        height: 100%;
        background: $color-white;
        transform: translateX(-100%);
        // &::before{
        //     position: absolute;
        //     content: "";
        //     top: 0%;
        //     left: 0;
        //     width: 100%;
        //     height: 1px;
        //     background: rgba($color-primary, 0.5);
        // }
    }
}

.project-square-mask-line-1{
    top: 10px;
}
.project-square-mask-line-2{
    top: 57px;
}
.project-square-mask-line-3{
    top: 102px;
}

.project-square-blur{
    // display: none;
    position: absolute;
    top: calc(-50vh + 125px);
    left: calc(-50vw + 125px);
    width: 100vw;
    height: 100vh;
    background: center center;
    background-size: cover;
    filter: blur(10px);
    &::after{
        position: absolute;
        background: rgba($color-primary, 0.12);
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}


.project-title{
    position: relative;
    padding: 0;
    margin: 0;
    margin-top: 115px;
    transform: translateY(-50%);
    padding-left: $application-border-padding;
    padding-right: $application-border-padding;
    overflow: hidden;
    color: rgba($color-white, 0.9);
    .project-title-word{
        width: 100%;
        font-size: 1.35rem;
        font-weight: 100;
        margin: 0;
        padding: 0;
        position: relative;
        display: inline-block;
        line-height: 1;
        margin-bottom: -8px;
        overflow: hidden;
        .bar{
            position: absolute;
            right: 12px;
            bottom: 37px;
            width: 0px;
            height: 2px;
            background: rgba($color-primary, 0.9);
            transform: translateY(13px);
        }
        .project-letter{
            overflow: hidden;
            // position: relative;
            transform: translateY(13px);
            // opacity: 0;
            width: 34.5px;
            height: 45px;
            text-align: center;
            margin: 0;
            padding: 0;
            line-height: 1;
            float: left;
        }
    }
}

.project-infos{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: auto;
    border-top: 1px solid $color-white;
    overflow: hidden;
}

.project-infos-year{
    position: relative;
    margin: 0;
    font-weight: 100;
    padding-left: #{$application-border-padding+3px};
    background: $color-white;
    color: $color-primary;
    width: 80px;
    height: 40px;
    overflow: hidden;
    float: left;
    span{
        line-height: 44px;
        position: absolute;
        transition: transform 0.3s ease-Out, opacity 0.3s ease-Out;
    }
}

.project-infos-more{
    transform: translateY(100%);
    margin-left: -6px;
    opacity: 0;
}


.project-infos-type{
    position: relative;
    margin: 0;
    font-weight: 100;
    padding-right: #{$application-border-padding+3px};
    width: 168px;
    height: 40px;
    line-height: 43px;
    overflow: hidden;
    float: right;
    text-align: right;
    transition: opacity 0.3s;
}



.project-loading{
    position: absolute;
    height: 40px;
    width: 0;
    transition: width 0.2s ease-out;
    background: $color-white;
    bottom: 0;
    right: 0;
    overflow: hidden;
}

// .project-loading-arrow{
//     position: absolute;
//     color: $color-primary;
//     line-height: 42px;
//     right: 0;
//     transform: translateX(-80px);
//     opacity: 0;
//     transition: transform 0.3s, opacity 0.3s;
// }

.project-loading-arrow{
    position: absolute;
    // background: $color-primary;
    height: 2px;
    width: 50px;
    right: 7px;
    top: 48%;
    transform: translateX(-80px);
    opacity: 0;
    transition: transform 0.3s, opacity 0.3s;
    &:before{
        content: "";
        position: absolute;
        background: $color-primary;
        height: 2px;
        width: 10px;
        right: 0;
        margin-right: 0px;
        top: -3px;
        transform: rotate(40deg);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        -o-backface-visibility: hidden;
        backface-visibility: hidden;
    }
    &:after{
        content: "";
        position: absolute;
        background: $color-primary;
        height: 2px;
        width: 10px;
        right: 0;
        margin-right: 0px;
        top: 3px;
        transform: rotate(-40deg);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        -o-backface-visibility: hidden;
        backface-visibility: hidden;
    }
}

.project-loading-progress{
    position: absolute;
    left: 0;
    top: 19px;
    height: 2px;
    width: 0%;
    background: $color-primary;
}


.project-square.active{
    .project-infos-year span:nth-child(1){
        transform: translateY(-100%);
        opacity: 0;
    }
    .project-infos-more{
        transform: translateY(0);
        opacity: 1;
    }
    .project-infos-type{
        opacity: 0;
    }
    .project-loading{
        width: 168px;
    }
    .project-loading-arrow{
        transform: translateX(-23px);
        opacity: 1;
    }
}


.project-square.loading{
    .project-infos-year span:nth-child(1){
        transform: translateY(-100%);
        opacity: 0;
    }
    .project-infos-more{
        transform: translateY(0);
        opacity: 1;
    }
    .project-infos-type{
        opacity: 0;
    }
    .project-loading{
        width: 168px;
    }
    .project-loading-arrow{
        transform: translateX(-23px);
        opacity: 0;
    }
}

ul.onepage-pagination{
    position: absolute;
    right: $application-viewport-padding;
    top: 50%;
    margin-top: -75px;
    z-index: 10;
    list-style: none;
    li{
        list-style: none;
        text-align: left;
        line-height: $line-height;
        a{
            cursor: pointer;
            font-weight: bold;
            position: relative;
            padding-left: $application-border-padding;
            transition: color 0.3s;
            &::before{
                transition: all 0.3s;
                position: absolute;
                content: "";
                left: #{-1*$application-border-padding};
                top: #{$line-height/4 - 1px};
                height: 1px;
                width: $application-border-padding;
                background: $color-white-lighter;
            }
            &.active{
                color: $color-primary;
                &::before{
                    background: $color-primary;
                    width: #{$application-viewport-padding + $application-border-padding};
                    height: 2px;
                    top: #{$line-height/4 - 1px};
                }
            }
        }
    }
}


.project-index-mask{
    z-index: $index-overlay;

    position: absolute;
    left: 50%;
    bottom: #{$application-viewport-padding + 1.5*$application-border-padding};
    transform: translateX(-71px);
    width: 150px;
    height: 50px;
    overflow: hidden;
}

.project-index{
    width: 150px;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    line-height: 40px;
    font-size: 40px;
    letter-spacing: 0.5rem;
    font-weight: bold;
    color: $color-white;

    overflow: hidden;
    span{
        display: block;
        position: relative;
        width: 100%;
        // height: 100%;
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 0 auto;
    }
}

.project-index-slash{
    z-index: $index-overlay;
    position: absolute;
    line-height: 1;
    left: 50%;
    bottom: #{$application-viewport-padding+50+2*$application-border-padding+6};
    font-size: 2rem;
    color: rgba($color-white, 0.4);
    letter-spacing: 0.25rem;
    font-weight: bold;
}

.project-next {
    position: absolute;
    bottom: $application-viewport-padding;
    left: 50%;
    transform: translateX(-50%);
    z-index: $index-overlay;
    font-size: 1rem;
    a{
        transition: color 0.3s;
        &:hover{
            color: $color-primary;
        }
    }
}

.project-next-border{
    position: absolute;
    width: 1px;
    height: calc(50vh - 125px - 260px);
    left: 50%;
    bottom: #{$application-viewport-padding + 100px};
    background: rgba($color-white, 0.2);
    overflow: hidden;
}

.project-next-border-highlight{
    position: absolute;
    width: 100%;
    height: 100%;
    background: $color-white;
    animation: homeWork 3s infinite ease-in-out;
}

.project-next-link{
    text-transform: uppercase;
    cursor: pointer;
    color: $color-white;
}
