.header-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
}

.header-developer{
    position: absolute;
    left: 0;
    text-transform: uppercase;
    color: $color-white;
    margin: $application-viewport-padding;
    z-index: $index-header;
    transition: all 0.3s;
    &:hover{
        .header-developer-back, .header-developer-back.active{
            transition: all 0.3s!important;
            background: $color-primary;
            &:before, &:after{
                background: $color-primary;
            }
        }
    }
}

.header-menu-button{
    position: absolute;
    right: 0;
    color: $color-white;
    margin: $application-viewport-padding;
    z-index: $index-header;

}

.header-about-button{
    color: $color-white;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    padding: 14px;
    transform: rotate(-90deg) translateX(50%);
    z-index: $index-header;
    transition: color 0.3s;
    top: 50vh;
    &::before{
        content: "";
        position: absolute;
        height: 1px;
        width: 25px;
        left: -25px;
        top: 19px;
        background: $color-white-lighter;
    }
}

.header-works-button{
    color: $color-white;
    text-transform: uppercase;
    position: absolute;
    left: -3px;
    padding: 14px;
    transform: rotate(-90deg) translateX(50%);
    z-index: $index-header;
    transition: color 0.3s;
    top: 50vh;
    &::before{
        content: "";
        position: absolute;
        height: 1px;
        width: 25px;
        left: -25px;
        top: 19px;
        background: $color-white-lighter;
    }
}

.header-developer-back{
    position: relative;
    display: inline-block;
    background: $color-white;
    height: 1px;
    width: 0px;
    margin-left: 10px;
    top: -3px;
    transition: all 0.3s;
    &:before{
        opacity: 0;
        content: "";
        position: absolute;
        background: $color-white;
        height: 1px;
        width: 10px;
        left: -1px;
        margin-right: 0px;
        top: -0px;
        transform: rotate(0deg);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        -o-backface-visibility: hidden;
        backface-visibility: hidden;
        transition: all 0.3s;
    }
    &:after{
        opacity: 0;
        content: "";
        position: absolute;
        background: $color-white;
        height: 1px;
        width: 10px;
        left: -1px;
        margin-right: 0px;
        top: 0px;
        transform: rotate(0deg);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        -o-backface-visibility: hidden;
        backface-visibility: hidden;
        transition: all 0.3s;
    }
}

.header-developer-back.active{
    width: 33px;
    margin-right: 10px;
    &:before{
        top: -3px;
        opacity: 1;
        transform: rotate(-40deg);
        // transition: transform 0.4s, opacity 0.3s;
    }
    &:after{
        top: 3px;
        opacity: 1;
        transform: rotate(40deg);
        // transition: transform 0.4s, opacity 0.3s;
    }
}
